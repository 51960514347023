<template>
    <div class="questions-container">
        <ChatbotFlowTextArea
            v-model="modelValue.text"
            :bindings="questionBindings"
            :readOnly="readOnly"
            :placeholder="placeholder"
            :resourceBindingTags="resourceBindingTags"
            highlight
            :rows="15"
        />

        <!-- if this isn't the first question, hide until there is some text -->
        <template v-if="isFirstQuestion || modelValue.text">
            <h3 class="logic-header">LOGIC</h3>

            <TabsContainer
                showAddTab
                :class="{ empty: hasNoAnswerOptions }"
                showCloseIcons
                @add-tab="addBlankAnswerOption"
                @close-tab="openRemoveAnswerOptionModal"
                addTabLabel="Add Answer Logic"
            >
                <template v-for="(answerOption, index) in modelValue.answerOptions">
                    <Tab
                        v-if="answerOption.toDelete !== true"
                        :id="answerOption.tabId"
                        :key="answerOption.tabId"
                        :label="answerOptionLabel(answerOption)"
                    >
                        <AnswerOption
                            v-model="modelValue.answerOptions[index]"
                            :tags="tags"
                            :questionBindings="questionBindings"
                            :interventionBindings="interventionBindings"
                            :riskBuckets="riskBuckets"
                            :readOnly="readOnly"
                            :resourceBindingTags="resourceBindingTags"
                            :finiteOptionsFlagEnabled="finiteOptionsFlagEnabled"
                        />
                    </Tab>
                </template>
            </TabsContainer>
        </template>
        <Modal
            v-if="removeAnswerOptionModalData.showModal"
            title="Remove Answer Option"
            primaryButtonTitle="Remove"
            @secondary-button-clicked="closeRemoveAnswerOptionModal"
            @primary-button-clicked="removeAnswerOption(removeAnswerOptionModalData.tabId)"
        >
            Are you sure you want to remove the answer option "{{
                removeAnswerOptionModalData.answerOptionText || 'New Answer'
            }}"?
        </Modal>
    </div>
</template>

<script>
import ChatbotFlowTextArea from '@/views/ChatbotFlowBuilder/components/ChatbotFlowTextArea';
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import { v4 } from 'uuid';
import { INTERVENTION_TEMPLATE_TYPES } from '@/consts/intervention';
import Modal from '@/components-deprecated/global/Modal';
import { defineAsyncComponent } from 'vue';
import { ANSWER_OPTIONS } from '../constants';

export default {
    name: 'QuestionRecursive',
    components: {
        Modal,
        Tab,
        TabsContainer,
        ChatbotFlowTextArea,
        // Because of the recursive nature of questions and answers,
        // QuestionRecursive and AnswerOption load each other asynchronously.
        AnswerOption: defineAsyncComponent(() =>
            import('@/views/ChatbotFlowBuilder/components/AnswerOption')
        )
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        questionBindings: {
            type: Object,
            required: true
        },
        interventionBindings: {
            type: Object,
            required: true
        },
        resourceBindingTags: {
            type: Object,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        tags: {
            type: Array,
            default: () => []
        },
        riskBuckets: {
            type: Array,
            default: () => []
        },
        isFirstQuestion: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            removeAnswerOptionModalDataDefaults: {
                showModal: false,
                tabId: null,
                answerOptionText: ''
            },
            removeAnswerOptionModalData: { ...this.removeAnswerOptionModalDataDefaults },
            finiteOptionsFlagEnabled: true
        };
    },
    methods: {
        answerOptionLabel(answerOption) {
            if (this.finiteOptionsFlagEnabled) {
                const option = ANSWER_OPTIONS[answerOption.text];
                return option ? option.label : 'New Answer';
            }

            return answerOption ? answerOption.text : 'New Answer';
        },
        addBlankAnswerOption() {
            if (!this.readOnly) {
                this.$emit('update:modelValue', {
                    ...this.modelValue,
                    answerOptions: [
                        ...this.modelValue.answerOptions,
                        {
                            text: '',
                            answerScore: { riskBucketId: null, score: 0, duration: 0 },
                            intervention: {
                                text: '',
                                type: INTERVENTION_TEMPLATE_TYPES.CHATBOT.value
                            },
                            tags: [],
                            tabId: v4(),
                            nextQuestion: {
                                text: '',
                                answerOptions: []
                            }
                        }
                    ]
                });
            }
        },
        openRemoveAnswerOptionModal(tabId) {
            if (!this.readOnly) {
                const answerOption = this.modelValue.answerOptions.find(
                    option => option.tabId === tabId
                );

                this.removeAnswerOptionModalData = {
                    ...this.removeAnswerOptionModalData,
                    showModal: true,
                    answerOptionText: answerOption.text,
                    tabId
                };
            }
        },
        closeRemoveAnswerOptionModal() {
            this.removeAnswerOptionModalData = {
                ...this.removeAnswerOptionModalDataDefaults
            };
        },
        removeAnswerOption(tabId) {
            if (!this.readOnly) {
                let newAnswerOptions;
                const answerOption = this.modelValue.answerOptions.find(
                    option => option.tabId === tabId
                );
                // if answerOption has no id, it is new - remove it from the list of options
                if (!answerOption.id) {
                    newAnswerOptions = this.modelValue.answerOptions.filter(
                        option => option.tabId !== answerOption.tabId
                    );
                } else {
                    // answerOption has an id - mark it for deletion by the API:
                    newAnswerOptions = this.modelValue.answerOptions.map(option => {
                        if (option.tabId === answerOption.tabId) {
                            return {
                                ...option,
                                toDelete: true
                            };
                        }
                        return option;
                    });
                }
                this.$emit('update:modelValue', {
                    ...this.modelValue,
                    answerOptions: newAnswerOptions
                });

                this.closeRemoveAnswerOptionModal();
            }
        }
    },
    computed: {
        hasNoAnswerOptions() {
            return (
                !this.modelValue ||
                !Array.isArray(this.modelValue.answerOptions) ||
                this.modelValue.answerOptions.filter(option => !option.toDelete).length === 0
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.questions-container {
    .tabs-container {
        &.empty {
            > :deep(.tabs) {
                padding-bottom: 0;
                .tab {
                    padding-left: 0;
                }
            }
        }
        > :deep(.tabs > .tab) {
            font-weight: bold;

            &.add-item {
                font-weight: normal;
            }
        }
    }
    > * {
        margin-bottom: 1rem;

        &.logic-header {
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .plus {
        font-size: 2rem;
        padding-right: 1rem;
    }
}
</style>
