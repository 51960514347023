<template>
    <modal
        classes="v--modal"
        name="modal-report-download"
        height="auto"
        width="500"
        :clickToClose="true"
        :stack="false"
        @before-open="beforeOpen"
        tabIndex="-1"
        aria-labelledby="export-modal-heading"
    >
        <div class="modal">
            <header class="modal-header">
                <h2 class="modal-header-title" id="export-modal-heading">{{ title }}</h2>
            </header>
            <fieldset class="modal-input-container">
                <legend v-if="showStudentGroupDropdown">Students to Include</legend>
                <template v-if="isExperimentalAccessibilityEnabled">
                    <DropdownV2
                        v-if="showStudentGroupDropdown"
                        :style="{ 'padding-bottom': '10px' }"
                        :options="studentGroupItems"
                        :selectedOptionValue="selectedStudentGroup"
                        @onChange="option => studentGroupSelected(option.value)"
                        class="full-width"
                    />
                </template>
                <template v-else>
                    <div v-if="showStudentGroupDropdown" class="student-categories">
                        <div class="input-group input-option">
                            <Dropdown
                                :options="studentGroupItems"
                                :label="studentGroupLabel"
                                @option-clicked="studentGroupSelected"
                            />
                        </div>
                    </div>
                </template>
                <div>
                    <legend>Select a date range:</legend>
                    <div class="date-input input-container">
                        <div>
                            <h3>START DATE</h3>
                            <div>
                                <DateInput v-model="startDate"/>
                            </div>
                        </div>
                        <div>
                            <h3>END DATE</h3>
                            <div>
                                <DateInput v-model="endDate"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <legend>Tag Groups to include:</legend>
                    <LoadingSpinner v-if="loading" />
                    <div class="tag-categories" v-else>
                        <div
                            class="input-group input-option"
                            v-for="tagCategory in tagCategories.list"
                            :key="tagCategory.id"
                        >
                            <BaseCheckbox
                                class="tag-category-checkbox"
                                :label="tagCategory.name"
                                :modelValue="isTagCategorySelected(tagCategory)"
                                @update:modelValue="toggleTagCategorySelection(tagCategory)"
                            />
                        </div>
                        <Paginator
                            v-if="tagCategories.pagination.next != null"
                            :nextPage="tagCategories.pagination.next != null"
                            @next-page="tagCategories.addNextPage()"
                        />
                    </div>
                </div>
                <div class="deactivated-student-row">
                    <legend>Include Deactivated Students</legend>
                    <BaseCheckbox
                        :ariaLabel="'Include deactivated students'"
                        :modelValue="includeDeactivateStudents"
                        @update:modelValue="onDeactivatedChange"
                    />
                </div>
            </fieldset>
            <div class="button-container">
                <Button styleType="off" type="submit" @click="close()"> Cancel </Button>
                <Button
                    type="submit"
                    @click="download()"
                    ref="submit"
                    :disabled="typeof selectedStudentGroup === 'undefined'"
                >
                    Download
                </Button>
            </div>
        </div>
    </modal>
</template>

<script>
import Button from '@/components-deprecated/Button';
import { BaseCheckbox } from '@edsights/ui-core';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Paginator from '@/components-deprecated/Paginator';

import CollectionManager from '@/services/collectionManager';
import TagCategories from '@/services/tagCategories';
import { mapState } from 'vuex';
import Dropdown from '@/components-deprecated/global/Dropdown.vue';
import DropdownV2 from '@/components-deprecated/global/v2/DropdownV2.vue';
import DateInput from '@/components-deprecated/global/DateInput.vue';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

const schoolsHidingAllOption = [
    // Portland State
    'a0f2062a-07eb-4774-b548-30d6014fcb30'
];

const getInitialData = () => ({
    tagCategories: CollectionManager.create({
        ModelClass: TagCategories,
        v2: true,
        slim: true
    }),
    showStudentGroupDropdown: false,
    selectedTagCategories: [],
    studentGroupItems: [
        { label: 'All who responded', value: '' },
        { label: 'Mentioned in Opportunities', value: 'ADMIN' },
        { label: 'Mentioned in Actions Taken', value: 'CHATBOT' },
        { label: 'Mentioned in Opportunities OR Actions Taken', value: 'ADMIN_OR_CHATBOT' }
    ],
    disabledStudentGroupItems: [],
    selectedStudentGroup: '',
    studentGroupLabel: 'All who responded',
    chatbotFlowId: null,
    title: 'Report Download Options',
    downloadReport: () => {},
    loading: true,
    includeDeactivateStudents: false,
    showDeactivatedStudentCheckbox: false,
    showDateRangeInput: false,
    startDate: null,
    endDate: null
});

export default {
    name: 'ModalReportDownload',
    components: {
        Dropdown,
        DropdownV2,
        Button,
        BaseCheckbox,
        LoadingSpinner,
        Paginator,
        DateInput
    },
    data() {
        return getInitialData();
    },
    methods: {
        onDeactivatedChange(value) {
            this.includeDeactivateStudents = value;
        },
        async beforeOpen(event) {
            // Reset the initial data:
            // In case this instance of the modal is being used again, this ensures any settings
            // from the last time it was opened are cleared.
            Object.assign(this.$data, getInitialData());

            if (event.params.showDeactivatedStudentCheckbox) {
                this.showDeactivatedStudentCheckbox = event.params.showDeactivatedStudentCheckbox;
            }

            if(event.params.showDateRangeInput) {
                this.showDateRangeInput = event.params.showDateRangeInput;
            }

            if (event.params.chatbotFlowId) {
                this.chatbotFlowId = event.params.chatbotFlowId;
            }

            if (event.params.title) {
                this.title = event.params.title;
            }

            if (event.params.downloadReport) {
                this.downloadReport = event.params.downloadReport;
            }

            if (event.params.showStudentGroupDropdown) {
                this.showStudentGroupDropdown = event.params.showStudentGroupDropdown;
            }

            if (event.params.disabledStudentGroupItems) {
                this.disabledStudentGroupItems = event.params.disabledStudentGroupItems;
            }

            if (event.params.studentGroupItems) {
                this.studentGroupItems = event.params.studentGroupItems;
            }

            if (
                Array.isArray(this.disabledStudentGroupItems) &&
                this.disabledStudentGroupItems.length
            ) {
                const disabledStudentGroupItems = [...this.disabledStudentGroupItems];

                // if 'ADMIN' or 'CHATBOT' items are disabled, disable the 'ADMIN_OR_CHATBOT' option too
                if (disabledStudentGroupItems.some(item => ['ADMIN', 'CHATBOT'].includes(item))) {
                    disabledStudentGroupItems.push('ADMIN_OR_CHATBOT');
                }

                this.studentGroupItems = this.studentGroupItems.map(item => {
                    if (disabledStudentGroupItems.includes(item.value)) {
                        return {
                            ...item,
                            disabled: true
                        };
                    }

                    return item;
                });
            }

            if (schoolsHidingAllOption.includes(this.user.schoolRef.id)) {
                this.studentGroupItems = this.studentGroupItems.filter(item => item.value !== '');
            }

            if (typeof event.params.initialSelectedStudentGroup === 'string') {
                this.studentGroupSelected(event.params.initialSelectedStudentGroup);
            } else {
                const { value: firstEnabledOptionValue } =
                    this.studentGroupItems.find(item => !item.disabled) || {};
                if (typeof firstEnabledOptionValue === 'string') {
                    this.studentGroupSelected(firstEnabledOptionValue);
                } else {
                    this.selectedStudentGroup = undefined;
                    this.studentGroupLabel = this.studentGroupItems[0].label;
                }
            }

            this.loading = true;
            this.tagCategories.pagination.page = 1;
            await this.tagCategories.refresh();
            this.loading = false;
        },
        opened() {
            this.$refs.submit.focus();
        },
        close() {
            this.$modal.hide('modal-report-download');
        },
        download() {
            let params = {
                selectedTagCategories: this.selectedTagCategories
            };

            if (this.chatbotFlowId) {
                params.chatbotFlowId = this.chatbotFlowId;
            }

            if (this.selectedStudentGroup) {
                params.studentGroup = this.selectedStudentGroup;
            }

            if (this.includeDeactivateStudents) {
                params.includeDeactivatedStudents = this.includeDeactivateStudents;
            }

            if (this.showDateRangeInput) {
                const { startDate, endDate } = this;

                if (startDate && endDate) {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                
                    if (start > end) {
                        this.$Alert.alert({
                            type: 'error',
                            message: 'Start date must be before end date.',
                            timeout: 3000
                        });
                        this.loaded = true;
                        return;
                    }
                }
                params.startDate = startDate;
                params.endDate = endDate;
            }

            this.downloadReport(params);
            this.$modal.hide('modal-report-download');
        },
        studentGroupSelected(value) {
            this.selectedStudentGroup = value;
            this.studentGroupLabel = this.studentGroupItems.find(o => o.value === value).label;
        },
        isTagCategorySelected(tagCategory) {
            return this.selectedTagCategories.find(t => t.id === tagCategory.id) ? true : false;
        },
        toggleTagCategorySelection(tagCategory) {
            if (this.isTagCategorySelected(tagCategory)) {
                this.selectedTagCategories = this.selectedTagCategories.filter(
                    t => t.id !== tagCategory.id
                );
            } else {
                this.selectedTagCategories.push(tagCategory);
            }
        }
    },
    computed: {
        ...mapState(['user', 'staffSchoolViewEnabled']),
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.modal {
    padding: 0;

    &-header {
        padding: 2.5rem;
    }

    &-content {
        padding: 24px;
        display: block;
        text-align: right;

        &-text {
            text-align: left;
            color: #000;
        }
    }

    .dropdown-container {
        z-index: 9999999;
    }

    .menu-container {
        z-index: 9999999;
    }
}

.name-input-label {
    margin-left: 15px;
    color: $accessible-gray;
}

.name-input {
    border-radius: 10px;
    border: 1px solid #1f8bfb;
    padding: 10px;
    margin: 10px;
    margin-top: 0px;
    width: 80%;
    &::placeholder {
        color: $accessible-gray !important;
    }
    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.modal-input-container {
    padding: 0rem 4rem;
    display: flex;
    flex-direction: column;
    border: 0;
}

.download-type-container {
    margin-bottom: 2rem;
}

.input-group {
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;

    > :last-child {
        margin-left: 0;
    }
}

.tag-categories {
    padding: 1rem 2rem;
    margin: 1rem;
    background: $off-white;
    border: 2px solid #e5eeed;
    border-radius: 10px;
    max-height: 300px;
    overflow-y: scroll;
}

.student-categories {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 1rem;
    margin: 1rem;
    background: $off-white;
    border: 2px solid #e5eeed;
    border-radius: 10px;
    max-height: 300px;

    .checkbox {
        height: 4rem;
    }

    .dropdown-container {
        width: 100%;
    }
}

.deactivated-student-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1rem;
}

.input-option {
    margin: 0.3rem 0;
}

.light-gray-input {
    border: 2px solid #e5eeed;
}

:deep(.tag-category-checkbox) {
    > .checkbox__checkmark {
        margin-right: 0;
    }
}

.button-container {
    margin: 2rem;
    display: flex;
    justify-content: flex-end;

    > :last-child {
        margin-left: 1rem;
    }
}

.date-input {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;

    .checkbox-label {
        margin-left: auto;
        padding-right: 5px;
    }

    .checkbox {
        padding-right: 12px;
    }

    .input-container {
        display: flex;
        gap: 5px;
    }
}
</style>
