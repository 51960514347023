export const ANSWER_OPTIONS = {
    YES: { value: 'YES', label: 'Yes' },
    NO: { value: 'NO', label: 'No' },
    1: { value: '1', label: '1' },
    2: { value: '2', label: '2' },
    3: { value: '3', label: '3' },
    4: { value: '4', label: '4' },
    5: { value: '5', label: '5' },
    6: { value: '6', label: '6' },
    7: { value: '7', label: '7' },
    8: { value: '8', label: '8' },
    9: { value: '9', label: '9' },
    10: { value: '10', label: '10' },
    MATCH_ALL_ANSWERS: { value: 'MATCH_ALL_ANSWERS', label: 'Match all answers' }
};
